<template>
  <b-modal
    id="modal-questions-answers-rejected"
    body-class="pt-2"
    hide-footer
    size="xl"
    header-class="align-items-center"
  >
    <template #modal-header="{ close }">
      <h5 class="mb-0">Fotos Rechazadas</h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>
    <section>
      <b-carousel
        id="carousel-answers-rejected"
        v-model="slide"
        controls
        indicators
        :interval="0"
        style="text-shadow: 1px 1px 2px #333"
        background="#ababab"
      >
        <!-- slides with image -->
        <b-carousel-slide
          v-for="currentImage in answersRejected"
          :key="currentImage.id"
        >
          <template #img>
            <img
              style="height: auto !important; max-height: 700px !important"
              class="d-block mx-auto"
              :src="currentImage.image"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
      <div class="mx-auto text-center">
        <h4 class="mt-3">
          N° {{ getCurrentAnswer.question.order }} -
          {{ getCurrentAnswer.question.name }}
        </h4>
        <p v-if="getCurrentAnswer && getCurrentAnswer.rejected_comment">
          Motivo de rechazo:
          <br />
          <small class="font-weight-bold">
            {{
              getCurrentAnswer.rejected_comment
                ? getCurrentAnswer.rejected_comment
                : "No especificado"
            }}
          </small>
        </p>
        <p class="mt-3" v-if="getCurrentAnswer && getCurrentAnswer.rejected_at">
          Rechazado el:
          <br />
          <small class="font-weight-bold">
            {{
              moment(getCurrentAnswer.rejected_at).format(
                "DD/MM/YYYY HH:mm [hrs]"
              )
            }}
          </small>
        </p>
        <p v-if="getCurrentAnswer && getCurrentAnswer.rejected_by">
          Rechazada por:
          <br />
          <small class="font-weight-bold">
            {{
              getCurrentAnswer.rejected_by.first_name
                ? `${getCurrentAnswer.rejected_by.first_name} ${getCurrentAnswer.rejected_by.last_name}`
                : "Super Administrador"
            }}
          </small>
        </p>
      </div>
    </section>
    <div class="d-flex justify-content-end flex-wrap">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { verifyRole } from "@/mixins/role";

export default {
  mixins: [verifyRole],
  props: {
    answersRejected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment,
      loading: false,
      loadingMarkReplaced: false,
      slide: 0,
    };
  },
  computed: {
    getCurrentAnswer() {
      return this.answersRejected[this.slide]
        ? this.answersRejected[this.slide]
        : null;
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-questions-answers-rejected");
    },
  },
};
</script>

<style></style>
